
var Walkthrough;
Walkthrough = {
    index: 0,
    nextScreen: function () {
        if (Walkthrough.index < Walkthrough.indexMax()) {
            Walkthrough.index++;
            return Walkthrough.updateScreen();
        }
    },
    prevScreen: function () {
        if (Walkthrough.index > 0) {
            Walkthrough.index--;
            return Walkthrough.updateScreen();
        }
    },
    updateScreen: function () {
        Walkthrough.reset();
        Walkthrough.goTo(Walkthrough.index);
        return Walkthrough.setBtns();
    },
    setBtns: function () {
        var $lastBtn, $nextBtn, $prevBtn;
        $nextBtn = $('.next-screen');
        $prevBtn = $('.prev-screen');
        $lastBtn = $('.finish');
        if (Walkthrough.index === Walkthrough.indexMax()) {
            $nextBtn.prop('disabled', true);
            $prevBtn.prop('disabled', false);
            return $lastBtn.addClass('active').prop('disabled', false);
        } else if (Walkthrough.index === 0) {
            $nextBtn.prop('disabled', false);
            $prevBtn.prop('disabled', true);
            return $lastBtn.removeClass('active').prop('disabled', true);
        } else {
            $nextBtn.prop('disabled', false);
            $prevBtn.prop('disabled', false);
            return $lastBtn.removeClass('active').prop('disabled', true);
        }
    },
    goTo: function (index) {
        $('.screen').eq(index).addClass('active');
        return $('.dot').eq(index).addClass('active');
    },
    reset: function () {
        return $('.screen, .dot').removeClass('active');
    },
    indexMax: function () {
        return $('.screen').length - 1;
    },
    closeModal: function () {
        $('.walkthrough, .shade').removeClass('reveal');
        return setTimeout(function () {
            $('.walkthrough, .shade').removeClass('show');
            Walkthrough.index = 0;
            $(".walkthrough-list").empty();
            return Walkthrough.updateScreen();
        }, 200);
    },
    openModal: function () {
        $('.walkthrough, .shade').addClass('show');
        setTimeout(function () {
            return $('.walkthrough, .shade').addClass('reveal');
        }, 200);
        return Walkthrough.updateScreen();
    }
};

$('.next-screen').click(function () {
    return Walkthrough.nextScreen();
});
$('.prev-screen').click(function () {
    return Walkthrough.prevScreen();
});
$('.close').click(function () {
    $(".walkthrough-modal").removeClass('show-walkthrough-modal');
    Walkthrough.closeModal();
});
$('.open-Walkthrough').click(function () {
    $(".walkthrough-modal").addClass('show-walkthrough-modal');
    Walkthrough.openModal();
});

