
/**
 * Created by John on 6/2/2016.
 */




var BrowserInfo = {
  timeOpened: new Date(),
  timezone: (new Date()).getTimezoneOffset() / 60,

  pageon: function () { return window.location.pathname; },
  referrer: function () { return document.referrer; },
  previousSites: function () { return history.length; },

  browserName: function () { return navigator.appName; },
  browserEngine: function () { return navigator.product; },
  browserVersion1a: function () { return navigator.appVersion; },
  browserVersion1b: function () { return navigator.userAgent; },
  browserLanguage: function () { return navigator.language; },
  browserOnline: function () { return navigator.onLine; },
  browserPlatform: function () { return navigator.platform; },
  javaEnabled: function () { return navigator.javaEnabled(); },
  dataCookiesEnabled: function () { return navigator.cookieEnabled; },
  dataCookies1: function () { return document.cookie; },
  dataCookies2: function () { return decodeURIComponent(document.cookie.split(';')); },
  dataStorage: function () { return localStorage; },

  /**Returns if the UI has been rendered by a mobile browser -> this is unreliable since browsers can be configured to fake the userAgent */
  isMobile: function () {
    var h = window.outerHeight;
    var w = window.outerWidth;
    // First line of defense -> test if screen height > screen width (assumption being that mobile devices are usually held in the portrait mode)
    if (h > w) {
      return true;
    }

    // Second line of defense -> test the userAgent
    if (navigator && navigator.userAgent && navigator.userAgent.toLowerCase().indexOf("mobi") > -1) {
      return true;
    }

    return false;
  },
  isMinimumDimensions: function () {
    var h = window.outerHeight;
    var w = window.outerWidth;
    if (w < 1280) {
      return false;
    }
    if (h < 600) {
      return false;
    }
    return true;
  },

  sizeScreenW: function () { return screen.width; },
  sizeScreenH: function () { return screen.height; },
  sizeDocW: function () { return document.width; },
  sizeDocH: function () { return document.height; },
  sizeInW: function () { return innerWidth; },
  sizeInH: function () { return innerHeight; },
  sizeAvailW: function () { return screen.availWidth; },
  sizeAvailH: function () { return screen.availHeight; },
  scrColorDepth: function () { return screen.colorDepth; },
  scrPixelDepth: function () { return screen.pixelDepth; },

  latitude: function () { return position.coords.latitude; },
  longitude: function () { return position.coords.longitude; },
  accuracy: function () { return position.coords.accuracy; },
  altitude: function () { return position.coords.altitude; },
  altitudeAccuracy: function () { return position.coords.altitudeAccuracy; },
  heading: function () { return position.coords.heading; },
  speed: function () { return position.coords.speed; },
  timestamp: function () { return position.timestamp; }
};

/**
 * Created by John on 6/29/2016.
 */

var Vouchers = {
  Types: {
    Static: {
      id: 100,
      name: 'Static'
    },
    /*
    StaticMulti:{
        id:150,
        name:"StaticMulti"
    },
    */
    Dynamic: {
      id: 200,
      name: 'Dynamic'
    },
    GiftCertificate: {
      id: 300,
      name: 'Gift Certificate'
    },
    Tito: {
      id: 400,
      Name: 'Ticket-In / Ticket-Out'
    }
  },
  CreatedBySources: {
    Comps: {
      id: 100,
      name: 'Single Voucher'
    },
    StaticSingleCampaign: {
      id: 150,
      name: 'Single Voucher Campaign'
    },
    // StaticMultiCampaign: {
    //   id: 200,
    //   name: 'Multi-Voucher Campaign'
    // },
    Dynamic: {
      id: 300,
      name: 'Dynamic Voucher'
    }
  },

  getByID: function (id) {
    switch (id) {
      case Vouchers.Types.Static.id:
        return Vouchers.Types.Static;
        break;
      case Vouchers.Types.Dynamic.id:
        return Vouchers.Types.Dynamic;
        break;
      default:
        return null;
    }
    return null;
  },
  getCreatedBySourceID: function (id) {
    switch (id) {
      case Vouchers.CreatedBySources.Comps.id:
        return Vouchers.CreatedBySources.Comps;
        break;
      case Vouchers.CreatedBySources.StaticSingleCampaign.id:
        return Vouchers.CreatedBySources.StaticSingleCampaign;
        break;
      case Vouchers.CreatedBySources.StaticMultiCampaign.id:
        return Vouchers.CreatedBySources.StaticMultiCampaign;
        break;
      case Vouchers.CreatedBySources.Dynamic.id:
        return Vouchers.CreatedBySources.Dynamic;
        break;
      default:
        return null;
    }
    return null;
  }
};






